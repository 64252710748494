.topbar-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 1.5rem;

  border-bottom: 1px solid $BORDER_PRIMARY;

  .topbar-left-content {
    flex: 1;

    .search-input {
      width: 35%;

      .ant-select-selector {
        background-color: $WHITE_PRIMARY;
        border: 1px solid $BORDER_PRIMARY;

        outline: none;

        font-size: 17px;

        height: 36px;

        .ant-select-selection-search-input {
          height: 36px;

          @include media-breakpoint-down(md) {
            height: 45px;
          }
        }

        .ant-select-selection-placeholder {
          line-height: 35px;
          color: rgba(0, 0, 0, 0.45);

          @include media-breakpoint-down(md) {
            line-height: 44px;
          }
        }

        .ant-select-selection-item {
          line-height: 35px;

          @include media-breakpoint-down(md) {
            line-height: 44px;
          }
        }

        @include media-breakpoint-down(md) {
          height: 45px;
        }
      }

      &:focus,
      &:hover,
      &:active {
        .ant-select-selector {
          border-color: $BORDER_PRIMARY;
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .topbar-right-content {
    display: flex;
    align-items: center;

    .topbar-dropdown-btn {
      background-color: inherit;
      border: none;

      width: 40px;
      height: 40px;

      margin-left: 10px;

      padding: 6px;

      border-radius: 50%;

      .btn-icon {
        width: 100%;
      }

      @include hover-focus {
        background-color: rgba($TEXT_PRIMARY, 0.075);
      }
    }
  }
}

.ant-dropdown {
  .ant-dropdown-menu-item {
    padding: 0 !important;

    .topbar-btn {
      background-color: inherit;
      border: none;

      padding: 5px 12px;

      font-size: 1rem;

      .topbar-icon {
        width: 16px;
        height: 16px;
        margin-bottom: 3.75px;
        margin-right: 6px;
      }
    }
  }
}
