.section-loader {
  height: calc(100vh - 57px);
  justify-content: center;
  align-items: center;
  background-color: inherit;

  .container {
    height: 100%;

    .row {
      height: 100%;
      align-items: center;
    }
  }
}

.modal-loader {
  padding: 2.5rem 0;
  justify-content: center;
  align-items: center;
  background-color: inherit;

  .container {
    height: 100%;

    .row {
      height: 100%;
      align-items: center;
    }
  }
}
