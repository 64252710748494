.input-field-wrapper {
  .input-label {
    font-family: $FONT_BASE;
    font-weight: 400;
    font-size: 1rem;
    color: $INPUT_PRIMARY;

    margin: 0 0 0.2rem;
    padding: 0;
  }

  .input-field {
    background-color: $WHITE_PRIMARY;
    border: 1px solid $BORDER_PRIMARY;
    border-radius: 6px;
    color: $INPUT_PRIMARY;
    font-size: 1rem;

    padding: 0.35rem 0.5rem;

    width: 100%;

    resize: none;
  }

  .input-field-full {
    width: 100%;
  }
}
