body {
  background-color: $BACKGROUND_PRIMARY;
}

.auth-content-section {
  height: 100vh;
  display: flex;

  .auth-content {
    width: 35%;
    margin: auto;
    padding: 40px 50px;
    background-color: $WHITE_PRIMARY;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .auth-icon {
      width: 150px;
      display: flex;

      margin: 0 auto 1rem;
    }

    .auth-content-title {
      font-size: 1.5rem;
      font-weight: normal;
      margin: 0;
      padding: 0;
      font-family: 'Roboto', sans-serif;
    }

    .auth-content-subtitle {
      font-size: 1rem;
      font-family: 'Roboto', sans-serif;
    }

    .auth-content-link {
      text-decoration: none;
      color: $BLUE_PRIMARY;
      transition: all 0.2s;

      &:hover {
        color: $BLUE_HOVER_PRIMARY;
        text-decoration: underline;
      }
    }

    .auth-form {
      input {
        width: 100%;
        box-sizing: border-box;
        padding: 15px 20px;
        background: #f8f8f8;
        border: 1px solid transparent;
        color: $TEXT_PRIMARY;

        margin-bottom: 1rem;
        font-size: 1rem;
        font-family: 'Roboto', sans-serif;
        position: relative;
        transition: all 0.2s;

        &:focus,
        &:hover {
          background: #fcfcfc;
          border: 1px solid $TEXT_PRIMARY;
          outline: none;
          outline-width: 0;
        }
      }

      .auth-form-btn {
        padding: 8px 20px;
        color: white;
        border: none;
        background-color: $BLUE_PRIMARY;
        transition: all 0.2s;

        &:hover,
        &:disabled {
          background-color: $BLUE_HOVER_PRIMARY;
        }
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;

      box-shadow: none;

      margin: 4rem 0 0;
    }
  }

  @include media-breakpoint-down(md) {
    background-color: $WHITE_PRIMARY;
  }
}
