.customers-table-wrapper {
  overflow-x: auto;

  .customers-table {
    width: 100%;

    background-color: $BACKGROUND_PRIMARY;

    .table-header {
      .table-row {
        border-top: 1px solid $BORDER_PRIMARY;
        border-bottom: 1px solid $BORDER_PRIMARY;

        .header-text {
          color: $TEXT_PRIMARY;
          font-family: $FONT_BASE;
          font-weight: 500;
          font-size: 0.85rem;

          padding: 0.5rem 1rem;
        }
      }
    }

    .table-body {
      .table-row {
        border-bottom: 1px solid $BORDER_PRIMARY;
        cursor: pointer;

        .table-text {
          color: $TEXT_PRIMARY;
          font-family: $FONT_BASE;
          font-size: 1rem;
          text-wrap: nowrap;

          padding: 0.5rem 1rem;

          &.text-bolden {
            font-weight: 600;
          }

          @include media-breakpoint-down(md) {
            padding: 0.75rem 1rem;
          }
        }

        &.not-selectable {
          cursor: default;
        }

        @include hover-focus {
          background-color: darken($BACKGROUND_PRIMARY, 2);

          &.not-selectable {
            background-color: $BACKGROUND_PRIMARY;
          }
        }
      }
    }
  }
}
