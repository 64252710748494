.files-table-wrapper {
  overflow-x: auto;

  .files-table {
    width: 100%;

    background-color: $BACKGROUND_PRIMARY;

    .table-header {
      .table-row {
        border-top: 1px solid $BORDER_PRIMARY;
        border-bottom: 1px solid $BORDER_PRIMARY;

        .header-text {
          color: $TEXT_PRIMARY;
          font-family: $FONT_BASE;
          font-weight: 500;
          font-size: 0.85rem;

          padding: 0.5rem 1rem;
        }
      }
    }

    .table-body {
      .table-row {
        border-bottom: 1px solid $BORDER_PRIMARY;
        cursor: pointer;

        .table-text {
          color: $TEXT_PRIMARY;
          font-family: $FONT_BASE;
          font-size: 1rem;
          text-wrap: nowrap;

          padding: 0.5rem 1rem;

          &.text-bolden {
            font-weight: 600;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 225px;
          }

          @include media-breakpoint-down(md) {
            padding: 0.75rem 1rem;
          }
        }

        .table-icons {
          text-align: right;

          width: 5%;

          padding: 0 1rem;

          .icon-btn {
            display: inline-block;
            width: 30px;
            height: 30px;

            border-radius: 50%;

            background-color: inherit;
            border: none;

            @include hover-focus {
              background-color: rgba($TEXT_PRIMARY, 0.075);
            }

            .icon {
              width: 100%;
              margin: 0 0 0.2rem;
            }
          }
        }

        &.not-selectable {
          cursor: default;
        }

        @include hover-focus {
          background-color: darken($BACKGROUND_PRIMARY, 2);

          &.not-selectable {
            background-color: $BACKGROUND_PRIMARY;
          }
        }
      }
    }
  }
}
