/*
 * Sidebar
*/
/*
 * Sidebar
*/
.sidebar-wrapper {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  background-color: white;
  color: $TEXT_PRIMARY;
  border-right: 1px solid $BORDER_PRIMARY;

  @include transition(max-width 0.3s);

  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: 260px;
  }

  .sidebar-header {
    .header-img {
      width: 75%;

      padding: 1rem;
    }
  }

  .sidebar-content {
    .content-link {
      display: block;
      font-size: 1rem;
      color: $TEXT_PRIMARY;
      font-family: $FONT_BASE;
      font-weight: 600;
      text-decoration: none;
      border-left: 5px solid rgba(#fff, 0);

      padding: 0.5rem 1rem;

      &.active {
        border-left: 5px solid $BLUE_PRIMARY;
        color: $BLUE_PRIMARY;
      }
    }
  }

  @include media-breakpoint-down(md) {
    bottom: auto;
    border-right: none;
    border-bottom: 1px solid $BORDER_PRIMARY;

    .sidebar-header {
      text-align: center;

      .header-img {
        width: 50%;
      }
    }

    .sidebar-content {
      display: flex;
      justify-content: space-evenly;

      .content-link {
        border-left: none;
        border-bottom: 4px solid rgba(#fff, 0);

        padding: 0.25rem 1rem;

        &.active {
          border-left: none;
          border-bottom: 4px solid $BLUE_PRIMARY;
          color: $BLUE_PRIMARY;
        }
      }
    }
  }
}

.content {
  overflow: visible;
  padding: 0;
  @include transition(all 0.3s);

  @include media-breakpoint-up(md) {
    margin-left: 260px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 130px;
  }
}
