.app-modal {
  top: 30px;

  .ant-modal-content {
    padding: 0;

    .ant-modal-header {
      padding: 20px 25px;
      margin: 0;
      border-bottom: 1px solid $BORDER_PRIMARY;

      .ant-modal-title {
        font-family: $FONT_BASE;
        font-weight: 600;
        font-size: 1.25rem;
        color: $TEXT_PRIMARY;
      }
    }

    .ant-modal-body {
      padding: 20px 0;
    }
  }

  .modal-text {
    font-family: $FONT_BASE;
    font-weight: 500;
    font-size: 1.125rem;
    color: $TEXT_PRIMARY;

    margin: 0;
    padding: 0;

    &.sml-text {
      font-size: 0.9rem;
      word-wrap: break-word;
    }
  }

  .modal-form {
    .form-fields-flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }

    .form-fields-group {
      margin-bottom: 1rem;
    }

    .input-field-wrapper {
      margin-bottom: 0.5rem;
    }

    .modal-body {
      padding: 0 25px;
    }

    .form-uploaded-files {
      .file-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0 0 1rem;

        .file-link {
          color: $BLUE_PRIMARY;
          font-family: $FONT_BASE;
          font-size: 1rem;
          width: 80%;
        }

        .file-remove-btn {
          height: 20px;
          width: 20px;
          display: inline-block;

          background-color: inherit;
          border: none;

          margin: 0;
          padding: 0;

          .remove-icon {
            width: 100%;
          }
        }
      }
    }
  }

  .modal-footer {
    text-align: right;
    border-top: 1px solid $BORDER_PRIMARY;
    margin-top: 20px;
    padding: 20px 25px 0;

    .btn-secondary {
      margin-right: 0.75rem;
    }
  }

  &.pictures-modal {
    .modal-body {
      padding: 0 12.5px;

      .pictures-collection-wrapper {
        width: 100%;

        .collection-row {
          display: flex;
          flex-wrap: wrap;

          .collection-img-col {
            padding: 0.5rem 12.5px;

            width: 33.33%;

            .img-btn {
              cursor: pointer;

              display: inline-block;

              width: 100%;
              height: 150px;

              .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            @include media-breakpoint-down(md) {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
