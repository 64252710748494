.btn {
  font-family: $FONT_BASE;
  font-size: 1rem;

  padding: 0.2rem 1rem;
  border-radius: 6px;

  transition: 0.5s ease-out;

  @include media-breakpoint-down(md) {
    padding: 0.5rem 0.6rem;
  }
}

.btn-primary {
  @extend .btn;

  color: $WHITE_PRIMARY;
  background-color: $BLUE_PRIMARY;
  border: 1px solid $BLUE_PRIMARY;

  @include hover-focus {
    background-color: $BLUE_HOVER_PRIMARY;
    border: 1px solid $BLUE_HOVER_PRIMARY;
  }

  &:disabled {
    background-color: $BLUE_DISABLED_PRIMARY;
  }

  &.destructive-text {
    background-color: $RED_PRIMARY;
    border: 1px solid $RED_PRIMARY;

    @include hover-focus {
      background-color: $RED_HOVER_PRIMARY;
      border: 1px solid $RED_HOVER_PRIMARY;
    }
  }
}

.btn-secondary {
  @extend .btn;

  color: $TEXT_PRIMARY;
  background-color: $WHITE_PRIMARY;
  border: 1px solid $BORDER_PRIMARY;

  @include hover-focus {
    color: $TEXT_PRIMARY;
    background-color: $WHITE_PRIMARY;
    border: 1px solid $BORDER_PRIMARY;
  }
}

.btn-link {
  background-color: inherit;
  color: $BLUE_PRIMARY;
  border: none;

  font-size: 1.1rem;

  text-decoration: underline;

  margin: 0;
  padding: 0;

  transition: 0.5s ease-out;

  @include hover-focus {
    color: $BLUE_HOVER_PRIMARY;
  }
}
