.table-paginate-wrapper {
  padding: 0.5rem 0;

  display: flex;
  justify-content: space-between;

  .paginate-total-text {
  }

  .ant-pagination {
    .ant-pagination-item {
      margin-top: 5px;

      height: inherit;

      &.ant-pagination-item-active {
        background-color: $BLUE_PRIMARY;
        border: none;
        color: $WHITE_PRIMARY;
      }
    }

    .ant-pagination-item-link {
      @include hover-focus {
        background-color: inherit;
      }
    }
  }

  @include media-breakpoint-down(md) {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
