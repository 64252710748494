.files-collection-wrapper {
  overflow-x: auto;

  .collection-body {
    width: 100%;

    .collection-row {
      border-top: 1px solid $BORDER_PRIMARY;
      border-bottom: 1px solid $BORDER_PRIMARY;

      display: flex;
      flex-wrap: wrap;

      .collection-img-col {
        padding: 0.5rem 1rem;

        width: 25%;

        .img-btn {
          cursor: pointer;

          display: inline-block;

          width: 100%;
          height: 100%;

          .img {
            width: 100%;
            height: 100%;

            object-fit: cover;
          }
        }

        @include media-breakpoint-down(md) {
          width: 50%;
        }
      }

      .collection-empty-col {
        padding: 0.5rem 1rem;
        text-align: center;

        width: 100%;

        .empty-text {
          color: $TEXT_PRIMARY;
          font-family: $FONT_BASE;
          font-size: 1rem;

          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .collection-footer {
    padding-top: 0.5rem;

    text-align: right;
  }
}
