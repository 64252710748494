.alt-details-drawer {
  .drawer-body {
    .details-wrapper {
      margin: 0 0 1.5rem;

      .details-header {
        display: flex;
        justify-content: space-between;

        padding: 0 0 0.75rem;
        margin: 0 0 0.75rem;

        border-bottom: 1px solid $BORDER_PRIMARY;

        .header-title {
          color: $TEXT_PRIMARY;
          font-family: $FONT_BASE;
          font-weight: 600;
          font-size: 1.125rem;

          margin: 0;
          padding: 0;
        }

        .header-btn {
          background-color: inherit;
          border: none;
          width: 20px;
          height: 20px;

          padding: 0;
          margin: 0;

          .btn-icon {
            width: 100%;
          }
        }
      }

      .details-body {
        .details-block {
          padding: 0 0 0.75rem;
          margin: 0 0 0.75rem;

          border-bottom: 1px solid $BORDER_PRIMARY;

          .details-flex {
            display: flex;
            justify-content: space-between;
            align-items: start;

            .details-text-wrapper {
              .details-text {
                color: $TEXT_PRIMARY;
                font-family: $FONT_BASE;
                font-weight: 500;
                font-size: 1rem;

                margin: 0;
                padding: 0;
              }
            }

            .details-btn-wrapper {
              .btn-link {
                font-size: 1rem;
                margin: 0 0 0 0.5rem;

                &.text-red {
                  color: $RED_PRIMARY;
                }
              }
            }
          }

          .details-primary-text {
            color: $TEXT_PRIMARY;
            font-family: $FONT_BASE;
            font-weight: 500;
            font-size: 0.9rem;

            margin: 0.25rem 0 0;
            padding: 0;
          }

          .details-note-text {
            color: $TEXT_PRIMARY;
            font-family: $FONT_BASE;
            font-weight: 500;
            font-size: 0.9rem;

            margin: 0.25rem 0 0;
            padding: 0;

            white-space: pre-line;

            span {
              text-decoration: underline;
            }
          }

          .btn-link {
            font-size: 0.8rem;

            margin-top: 0.25rem;
          }
        }
      }
    }
  }
}

.ant-drawer-content-wrapper {
  width: 450px !important;

  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
}
