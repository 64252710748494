.customer-sidebar-wrapper {
  width: 100%;
  max-width: 275px;

  @include media-breakpoint-down(md) {
    max-width: 100%;

    margin-bottom: 1rem;
  }

  .customer-title {
    font-family: $FONT_BASE;
    font-weight: 600;
    font-size: 1.75rem;
    color: $TEXT_PRIMARY;

    margin: 0 0 0.25rem;
    padding: 0;
  }

  .customer-subtitle {
    font-size: 1rem;
    color: $INPUT_PRIMARY;

    text-decoration: none;
    word-wrap: break-word;

    transition: 0.5s ease-out;

    margin: 0;
    padding: 0;

    @include hover-focus {
      color: $BLUE_PRIMARY;
    }
  }

  .customer-details-wrapper {
    .details-header {
      border-bottom: 1px solid $BORDER_PRIMARY;

      margin: 0.75rem 0 0.5rem;
      padding: 0 0 0.5rem;

      display: flex;
      justify-content: space-between;

      .header-title {
        font-family: $FONT_BASE;
        font-weight: 600;
        font-size: 1.1rem;
        color: $TEXT_PRIMARY;

        margin: 0;
        padding: 0;
      }

      .btn-link {
        font-size: 0.95rem;
      }
    }

    .details-body {
      .details-content {
        margin: 0 0 1rem;

        .details-title {
          font-family: $FONT_BASE;
          font-weight: 600;
          font-size: 1rem;
          color: $INPUT_PRIMARY;

          margin: 0 0 0.25rem;
          padding: 0;
        }

        .details-text {
          font-size: 1rem;
          color: $TEXT_PRIMARY;

          text-decoration: none;

          margin: 0;
          padding: 0;
        }

        .details-text-copy {
          font-size: 1rem;
          color: $TEXT_PRIMARY;

          background-color: $WHITE_PRIMARY;
          border: 1px solid $BORDER_PRIMARY;

          border-radius: 5px;

          margin: 0;
          padding: 3px 6px;

          transition: 0.5s ease-out;

          @include hover-focus {
            background-color: $BORDER_PRIMARY;
          }
        }

        &:last-child {
          margin: 0 0 0.5rem;
        }
      }
    }

    .details-footer {
      border-top: 1px solid $BORDER_PRIMARY;

      padding: 0.5rem 0 0;

      .details-button-link {
        font-size: 1rem;
        color: $BLUE_PRIMARY;
        background-color: inherit;
        text-decoration: none;
        border: none;

        margin: 0;
        padding: 0;

        .link-icon {
          width: 20px;
          height: 20px;

          margin-left: 5px;
          margin-bottom: 2px;
        }
      }
    }
  }
}
