.single-customer-section {
  padding: 1.5rem;

  display: flex;

  .customer-content-wrapper {
    width: 100%;
    margin-left: 2rem;

    .content-invoice-block,
    .content-proposal-block,
    .content-photos-block,
    .content-other-block {
      margin: 0 0 2rem;

      .content-header-wrapper {
        display: flex;
        justify-content: space-between;

        margin: 0 0 0.5rem;

        .content-title {
          font-family: $FONT_BASE;
          font-weight: 600;
          font-size: 1.25rem;
          color: $TEXT_PRIMARY;

          margin: 0;
          padding: 0;
        }
      }
    }

    @include media-breakpoint-down(md) {
      margin: 0;
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
    padding: 1.5rem 1.5rem 4rem;
  }
}
