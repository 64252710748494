.files-section {
  padding: 1.5rem;

  .files-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0 0 1rem;

    .files-title {
      font-family: $FONT_BASE;
      font-weight: 600;
      font-size: 2rem;
      color: $TEXT_PRIMARY;

      @include media-breakpoint-down(md) {
        margin: 0 0 1rem;
      }
    }

    .btn-primary {
      @include media-breakpoint-down(md) {
        margin: 0 0 1rem;
        width: 100%;
      }
    }

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 1.5rem 1.5rem 4rem;
  }
}
