$BLUE_PRIMARY: #3059b0;
$BLUE_HOVER_PRIMARY: #1a4399;

$RED_PRIMARY: #fd0000;
$RED_HOVER_PRIMARY: #d20a0a;

$BLUE_DISABLED_PRIMARY: #4478e7;

$WHITE_PRIMARY: #fff;

$TEXT_PRIMARY: #1c1d25;

$INPUT_PRIMARY: #3a3a53;

$BORDER_PRIMARY: rgba($INPUT_PRIMARY, 0.15);
$PLACEHOLDER_PRIMARY: rgba($INPUT_PRIMARY, 0.4);

$BACKGROUND_PRIMARY: #f5f6f8;

$transition-base: all 0.2s ease;
$transition-transform: transform 0.2s ease;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;
$transition-tabs: all 0.2s;

$FONT_BASE: 'Open Sans', sans-serif;
